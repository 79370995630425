@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

img {
  mask-image: linear-gradient(to bottom, black 100%, transparent);
}

.no-mask {
  mask-image: none;
}